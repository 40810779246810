@import url('https://fonts.googleapis.com/css2?family=Bitter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hiddenTextBox{
    opacity: 0;
    filter: blur(5px);
    transform: translateY(50%);
    transition: all .75s;
}

.show{
    opacity: 1;
    filter: blur(0);
    transform: translateY(0%);
    transition: all .75s;
}



@layer utilities {
    @variants hover, focus {
        .button-brightness {
            box-shadow: inset 0 0 28px 12px rgba(255, 255, 255, 0.3);
        }

        .gradient-border-bottom {
            color: hsl(233, 26%, 24%);
            border-width: 0 0 4px 0;
            border-style: solid;
            border-image: linear-gradient(
                    to right,
                    hsl(114, 51%, 34%),
                    hsl(94, 39%, 38%)
            );
        }
    }

    .navbarFadeOut{
        visibility: hidden;
        filter: blur(5px);
        transition: all 250ms;
    }

    .navbarFadeIn{
        visibility: visible;
        filter: blur(0px);
        transition: all 250ms;
    }

    @media(prefers-reduced-motion) {
        .navbarFadeOut{
            transition: none;
        }
    }
}

body::after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('../src/images/sales.jpg');
    opacity: 0.03;
    pointer-events: none;
    z-index: -10;
}

ul.checklist {
    margin-top: 1em;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

li.checklist {
    padding-left: 1em;
    margin: -1em;
    text-indent: -1em;
}

li.checklist:before {
    content: '✓';
    font-size: 1.25em;
    padding-right: 5px;
}

p{
    line-height: 1.7em;
}

.capital{
    text-transform: capitalize;
    font-weight: 800;
}

li.indent-line:before {
    content: '-';
    font-size: 1.25em;
    padding-right: 5px;
}



